<template>
  <div class="headers">
    <div class="message" v-if="message.length > 0">
      <img src="../assets/img/warning.png">
      <el-carousel height="44px" direction="vertical" indicator-position="none" :interval="3000" autoplay>
        <el-carousel-item v-for="(item, index) in message" :key="index">
          <el-tooltip effect="dark" :disabled="item.showTooltip" placement="bottom">
            <span v-if="item.expirationDate <= 0" slot="content">商品有效期告警：{{ item.productName }}剩余有效期0天</span>
            <span v-else slot="content">商品有效期告警：{{ item.productName }}剩余有效期{{ item.expirationDate }}天</span>
            <div ref="productName" class="name" @click="goWarning">
              商品有效期告警：{{ item.productName }}剩余有效期{{ item.expirationDate }}天
            </div>
          </el-tooltip>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="head_wrap">
      <div class="head">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <div class="user">
              <el-avatar :src="obj.headPic || avatar"></el-avatar>
              <div class="username">{{ obj.userName }}</div>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="info">个人信息</el-dropdown-item>
            <el-dropdown-item command="password">修改密码</el-dropdown-item>
            <el-dropdown-item command="exit">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      avatar: require('@/assets/img/avatar.png'),
      obj: {},
      message: [],
    };
  },
  created () {
    this.obj = this.$store.state.userInfo;
    this.getDeliveryProductList()
  },
  watch: {
    userInfo (val) {
      if (val) {
        this.obj = val
      }
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    handleCommand (command) {
      this.$emit('command', command)
    },
    // 保质期预警跳转
    goWarning () {
      localStorage.removeItem('lastDetailPath')
      this.$store.commit("setIsDetail", null);
      this.$store.commit("setSideMenuActive", '/productMgt/shelfLifeWarning');
      this.$router.push('/productMgt/shelfLifeWarning')
    },
    // 保质期预警列表
    getDeliveryProductList () {
      this.$axios.get(this.$api.deliveryProductList, {
        params: {
          page: 1,
          pageSize: 5,
        },
      }).then((res) => {
        if (res.data.result.list.length > 0) {
          this.message = res.data.result.list.map(e => {
            return {
              ...e,
              showTooltip: e.productName.length < 15 ? true : false
            }
          })
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.headers {
  display: flex;
  justify-content: right;
  width: 100%;
  height: 44px !important;
  background-color: #EBFFFB;

  .message {
    display: flex;
    align-items: center;
    width: 400px;
    height: 44px;

    .el-carousel {
      width: 100%;

      .el-carousel__item {
        display: flex;
        align-items: center;
        color: #EB2500;
        font-size: 15px;
        cursor: pointer;

        .name {
          display: -webkit-box;
          word-break: break-all;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    img {
      width: 20px;
      height: 18px;
      margin-right: 10px;
    }
  }

  .head_wrap {
    width: 280px;
    height: 44px;
    padding-right: 25px;
    background: url(../assets/img/user.png) no-repeat;
    background-size: cover;
    box-sizing: border-box;

    .head {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 44px;
      color: #fff;
      font-size: 16px;
      margin: 0 auto;

      .user {
        display: flex;
        align-items: center;

        .el-avatar {
          width: 22px;
          height: 22px;
        }

        .username {
          width: 100px;
          color: #fff;
          font-size: 16px;
          margin-left: 10px;
          cursor: pointer;

          display: -webkit-box;
          word-break: break-all;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }


}
</style>
